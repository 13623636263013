import { useTranslation } from "react-i18next"
import { useContext, useState } from "react"
import { PYTHON_API_URL } from "../../../../utils"
import dayjs from "dayjs"
import { SeriesContext } from "../../../../context/SeriesContext"
import { SelectionContext } from "../../../../context/SelectionContext"
import { errorMessage, handleError } from "../../../../errors"
import { toast } from "react-toastify"
import axios from "axios"
import fileDownload from "js-file-download"
import FieldsSection from "./sections/FieldsSection"
import OrderSection from "./sections/OrderSection"
import DateSection from "./sections/DateSection"
import LoadingSpinner from "../../../../helpers/LoadingSpinner"

var quarterOfYear = require("dayjs/plugin/quarterOfYear")
dayjs.extend(quarterOfYear)

const GroupFilters = () => {

    const { t } = useTranslation()

    const {

        groups,
    } = useContext(SeriesContext)

    const [taskMode, setTaskMode] = useState("statistics")

    const {
        group,
        setGroup,
        loadingFieldsValues,

        selectedFields,
        setSelectedFields,

        selectedFieldsOrder,

        lastUpdateDate,
        setLastUpdateDate,


        frequencyCount,

        selectedFrequencies,

        selectedDateColumns,

        outputFormat,

        startDate,
        setStartDate,

        endDate,
        setEndDate,

        includePreliminary,
        setIncludePreliminary,

        isDownloadEmpty,
        setSelectDateColumnRed



    } = useContext(SelectionContext)

    const [submittingDownload, setSubmittingDownload] = useState(false)

    const ModeSwitcher = () => (
        <div className="buttons has-addons">
            <div
                className={`button ${taskMode == "forecasts" ? "" : "is-success"}`}
                onClick={() => setTaskMode("statistics")}
            >
                {t('data.taskMode.statistics')}
            </div>
            <div
                className={`button ${taskMode == "forecasts" ? "is-success" : ""}`}
                onClick={() => setTaskMode("forecasts")}
            >
                {t('data.taskMode.forecasts')}
            </div>


        </div>
    )

    const downloadData = async () => {

        // Check if at least one data column is selected
        if (selectedDateColumns?.length === 0) {
            setSelectDateColumnRed(true)
            return toast.error(errorMessage("se:::028"))
        }

        if ((outputFormat.value === "flat") && (selectedFrequencies.length !== 1)) {
            return toast.error(errorMessage("se:::029"))
        }

        if ((filteredFrequencyCount > 5000) && (taskMode == 'statistics')) {
            return toast.error(errorMessage("se:::030"))
        }

        if ((filteredFrequencyCount > 500) && (taskMode == 'forecasts')) {
            return toast.error(errorMessage("se:::031"))
        }
        const data = {

            groupId: group,
            selectedFieldsColumn: Object.values(selectedFields).map(
                field => {

                    const curOrder = selectedFieldsOrder.find(
                        item => item.field === field.field && !item.isBlocked
                    )

                    return {
                        isActive: field?.isActive,
                        field: field?.field,
                        values: field?.values,
                        isAllSelected: field?.isAllSelected,
                        isUnmerged: curOrder ? curOrder?.isUnmerged : false,
                        order: curOrder ? curOrder?.order : -1
                    }
                }
            ),

            selectedFieldsRow: [],
            startDate: startDate,
            endDate: endDate,
            updateDate: lastUpdateDate,
            includePreliminary: includePreliminary,
            downloadEmpty: isDownloadEmpty,
            frequencies: selectedFrequencies?.map(frequency => frequency.value),
            dateColumns: selectedDateColumns?.map(dateColumn => dateColumn.value),
            outputFormat: outputFormat.value,
            taskMode: taskMode

        }




        try {
            setSubmittingDownload(true)

            const curGroup = groups?.find(gr => gr?.id === group)

            const config = {
                headers: {
                    "Content-Type": "application/json",
                },
                responseType: "arraybuffer",
            }

            const resp = await axios.post(
                `${PYTHON_API_URL}/restricted/data/download`,
                data,
                config
            )

            var filename
            switch (taskMode) {
                case "statistics":
                    switch (outputFormat.value) {
                        case "standard":
                            filename = `Result ${curGroup?.nameEn}.xlsx`
                            break
                        case "flat":
                            filename = `Result ${curGroup?.nameEn}.csv`
                            break
                    }
                default:
                    filename = `Result ${curGroup?.nameEn}.xlsx`

            }


            fileDownload(resp.data, filename)
        } catch (error) {
            return handleError(error)
        }
        setSubmittingDownload(false)
    }




    const clearForm = () => {
        setGroup(null)
        setSelectedFields({})
        setStartDate(new Date(dayjs().subtract(5, "y")))
        setEndDate(new Date())
        setLastUpdateDate(null)
        setIncludePreliminary(false)
        // setKeyGroup(Math.random())
    }


    const filteredFrequencyCount = selectedFrequencies.length === 0 ?
        Object.values(frequencyCount).reduce((a, b) => a + b, 0) :
        selectedFrequencies.reduce((total, frequency) => total + (frequencyCount[frequency.value] || 0), 0);


    const FrequencyCounter = () => {
        return (
            <div className="columns is-centered">
                <div className="column has-text-centered is-3">
                    <div className="label has-text-grey">
                        {`${t("series.totalCount")}${loadingFieldsValues ? "" : filteredFrequencyCount}`}
                    </div>
                </div>

            </div>
        )
    }
    return (
        <>

            <div className="columns is-centered">
                <div className="column">
                    <FrequencyCounter />

                    <FieldsSection />

                    <OrderSection />

                    <DateSection />




                    <div className="columns is-centered">
                        <div className="column is-one-quarter">
                            <div className="field">
                                <div className="control">
                                    <button
                                        className="button is-danger is-fullwidth"
                                        onClick={clearForm}
                                    >
                                        {t("series.clearForm")}
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="column is-one-quarter">
                            <div className="field">
                                <div className="control">
                                    <button
                                        className={`button is-link is-fullwidth ${submittingDownload ? "is-loading" : ""}`}
                                        onClick={() => downloadData()}
                                    >
                                        {t("data.download")}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="column is-narrow">
                            <ModeSwitcher />
                        </div>
                    </div>
                </div >
            </div>


        </>
    )
}

export default GroupFilters